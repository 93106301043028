::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

html{
  overflow: hidden;
}

.emoji-button {
  cursor: pointer;
  margin: 4px;
  font-size: 20px;
  display: inline-block;
}
